import { createContext, useState } from "react";
import { isLoggedIn, getSessionValue, businessIdHeader, fetchSignedMessage, getMethod } from "./components/Library";
import { useEffect } from "react";


export const LoyaltyOffersContext = createContext();

export function LoyaltyOffersProvider({ children }) {

    const [loyaltyOffers, setLoyaltyOffers] = useState([]);

    const addLoyaltyOffer = (loyaltyOffer) => {
        setLoyaltyOffers((prevState) => [...prevState, { loyaltyOffer }]);
    }

    useEffect(() => {
        const getLoyaltyData = async () => {
            const userLoggedIn = await isLoggedIn().then((loggedIn) => loggedIn);

            if (userLoggedIn) {
                await fetchSignedMessage(null, 'business/' + getSessionValue(businessIdHeader) + '/loyaltyoffers', getMethod)
                    .then((response) => {
                        const data = response.json();
                        setLoyaltyOffers(data);
                    });
            }
        }
        getLoyaltyData();
        }, []);
    
    return (
        <LoyaltyOffersContext.Provider value={{ hasOffers: (loyaltyOffers.length > 0), addLoyaltyOffer }}>{children}</LoyaltyOffersContext.Provider>
    );
}
