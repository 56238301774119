import React, { Component } from 'react'
import { fetchSignedMessage, getSessionValue, businessIdHeader, cssFormInputButton, cssFormInputLabel, getMethod, isLoggedIn, tenthOfASecond } from './Library'
import QRCode from 'react-qr-code'
import Logo from '../img/logo.png'
import { LoyaltyOffersProvider } from '../LoyaltyOffersContext';
import { NavMenu } from '../components/NavMenu';
import DualBallSpinner from '../img/dual-ball-1s-100px.gif';
import ReactDOM from 'react-dom';

export class LoyaltyOffers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loyaltyOffersData: [],
            business: null,
            loading: true
        }
    }

    async componentDidMount() {
        await isLoggedIn();
        this.getLoyaltyOffersData();
    }

    async getLoyaltyOffersData() {
        try {
            const businessId = getSessionValue(businessIdHeader);
            const response = await fetchSignedMessage(null, 'business/' + businessId + '/loyaltyoffers', getMethod);
            const businessResponse = await fetchSignedMessage(null, 'business/' + businessId, getMethod);
            const data = await response.json();
            const businessData = await businessResponse.json();

            this.setState({ loyaltyOffersData: data });
            this.setState({ business: businessData, loading: false });
        }
        catch (error) {
            console.log(error);
            const networkErrorEmail = "mailto:tech@receiptsandrewards.com?subject=Login%20-%20Network%20Error&body=A%20User%20is%20unable%20to%20logon%20to%20" + window.location.host;
            var messageLabel = document.getElementById("errorMessage");
            ReactDOM.render(<label className={cssFormInputLabel} >There appears to be a network error. Please <a href={networkErrorEmail}>contact Receipts and Rewards</a> and we will fix it.</label>, messageLabel);
            this.setState({ loading: false, buttonDisabled: false });
        }
    }

    render() {
        return (
            <LoyaltyOffersProvider>
                
                <div id="qrCodes">
                    <NavMenu />
                    <button className={cssFormInputButton} onClick={() => { window.print(); return false; }}>Print QR Codes</button>
                {
                        this.state.loyaltyOffersData.map(loyaltyOffer =>
                            <div className="qrCodePage" id={loyaltyOffer.loyaltyOfferId}>
                                <div className="print-logo"><img src={this.state.business.iconPath} className="print-logo"></img></div>
                            <div className="qrCode"><QRCode className="qr-image" value={process.env.REACT_APP_API_ENDPOINT + loyaltyOffer.redemptionController + "/" + loyaltyOffer.loyaltyOfferId} /><label>{loyaltyOffer.redemptionContoller}</label></div>
                            <div className="qr-scan-instructions"><label>Please scan QR Code to Update your Loyalty card</label></div>
                            <div className="print-rnr-logo"><img src={Logo} className="print-rnr-logo"></img></div>
                                <div className="loyaltyOffer-identifier"><label>{loyaltyOffer.description + (loyaltyOffer.description.length > 0 && loyaltyOffer.signUpAlertText.length > 0 ? " - " : "") + loyaltyOffer.signUpAlertText}</label></div>
                        </div>
                    )
                }
                </div>
                <div id="errorMessage"/>
                {this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : ''}
            </LoyaltyOffersProvider>
        );
    }

}