import React from 'react'
import { isLoggedIn, getSessionValue, businessIdHeader, fetchSignedMessage, cssFormInputLabel } from './Library'
import { Component } from 'react'
import QRCode from 'react-qr-code';
import PlusSign from '../img/plus-sign.png'
import MinusSign from '../img/minus-sign.png'
import { format } from 'date-fns'
import { LoyaltyOffersProvider } from '../LoyaltyOffersContext';
import { NavMenu } from '../components/NavMenu';
import DualBallSpinner from '../img/dual-ball-1s-200px.gif';
import ReactDOM from 'react-dom';

export class ReceiptLineItem {
    constructor(description, numberOfItems, unitCost, amount) {
        this.description = description;
        this.numberOfItems = numberOfItems;
        this.unitCost = unitCost;
        this.amount = amount;
    }
}

export class ReceiptsForBusiness extends Component {

    constructor(props) {
        console.log("constructor");
        super(props)
        this.state = {
            loading: true,
            receiptsData: []
        };
    }

    async componentDidMount() {
        await isLoggedIn();

        await this.getReceipstData();
    }

    async getReceipstData() {
        try {
            if (await isLoggedIn()) {
                const response = await fetchSignedMessage(null, 'business/' + getSessionValue(businessIdHeader) + '/receipts', 'GET');
                const receiptsData = await response.json();
                this.setState({ receiptsData: receiptsData, loading: false })
                this.LoadQRCodes(receiptsData);
            } else {

            }

        }
        catch (error) {
            console.log(error);
            const networkErrorEmail = "mailto:tech@receiptsandrewards.com?subject=Login%20-%20Network%20Error&body=A%20User%20is%20unable%20to%20logon%20to%20" + window.location.host;
            var messageLabel = document.getElementById("message");
            ReactDOM.render(<label className={cssFormInputLabel} >There appears to be a network error. Please <a href={networkErrorEmail}>contact Receipts and Rewards</a> and we will fix it.</label>, messageLabel);
            this.setState({ loading: false, buttonDisabled: false });
        }
    }

    
    toggleReceipt = (receiptId) => {
        const statekey = "visible" + receiptId;
        this.setState({
            [statekey]: !this.state[statekey]
        });
    }

    async LoadQRCodes(receiptsData) {
        receiptsData.map(receipt => this.setState({ [receipt.receiptPaymentId]: <QRCode value={process.env.REACT_APP_API_ENDPOINT + "receipt/" + receipt.receiptPaymentId} /> }));
        receiptsData.map(receipt => this.setState({ ["visible" + receipt.receiptPaymentId]: false }));
    }

    QRCodeForReceipt(receiptId) {
        return (this.state[receiptId] ? "loading" : <QRCode value={process.env.REACT_APP_API_ENDPOINT + "receipt/" + receiptId} />);
    }


    render() {
        return (
            <div>
                <LoyaltyOffersProvider>
                    <NavMenu />
                    {this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> :
                        <table>
                            <thead>
                                <tr>
                                    <th>Date and Time</th>
                                    <th>Amount</th>
                                    <th rowSpan="3">Receipt Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.receiptsData.map(receipt =>
                                    <tr>
                                        <td>{format(new Date(receipt.saleTime), 'HH:mm dd-MMM')}</td>
                                        <td align="right">{receipt.receiptOrder.total.toFixed(2)}</td>
                                        <td><img src={this.state["visible" + receipt.receiptPaymentId] ? MinusSign : PlusSign} className="btn-help" onClick={() => this.toggleReceipt(receipt.receiptPaymentId)} /></td>
                                        <td>
                                            <table className={"collapse" + (this.state["visible" + receipt.receiptPaymentId] ? ".in" : "")}>
                                                <tbody>
                                                    {receipt.receiptOrder.lineItems.map(lineItem =>
                                                        <tr>
                                                            <td>{lineItem.refunded ? <s>{lineItem.numberOfItems}</s> : lineItem.numberOfItems}</td>
                                                            <td>{lineItem.refunded ? <s>{lineItem.description}</s> : lineItem.description}</td>
                                                            <td align="right">{lineItem.refunded ? <s>{lineItem.unitCost}</s> : lineItem.unitCost}</td>
                                                            <td align="right">{lineItem.refunded ? <s>{(lineItem.unitCost * lineItem.numberOfItems).toFixed(2)}</s> : (lineItem.unitCost * lineItem.numberOfItems).toFixed(2)}</td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td>Total</td>
                                                        <td></td><td></td>
                                                        <td align="right">{receipt.receiptOrder.total.toFixed(2)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td><div className={"collapse" + (this.state["visible" + receipt.receiptPaymentId] ? ".in" : "")}>{this.state[receipt.receiptPaymentId]}</div></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                    <div id="message" />
            </LoyaltyOffersProvider >
            </div>
        );

    }
}