import React, { Component } from 'react'
import Select from 'react-select'
import { isLoggedIn, fetchSignedMessage, getSessionValue, businessIdHeader, merchantHasLoyaltyOffers, postMethod, cssFormInputLabel, cssFormInputDropdown, cssFormInput, cssFormInputNumber, cssFormInputButton, numberField, getMethod, cssError } from './Library'
import QRCode from 'react-qr-code'
import ReactDOM from 'react-dom'
import ShowHelp from '../img/help-icon.png'
import HideHelp from '../img/help-close-icon.png'
import { LoyaltyOffersProvider } from '../LoyaltyOffersContext';
import { NavMenu } from './NavMenu';
import DualBallSpinner from '../img/dual-ball-1s-100px.gif';

const additionalMatchesUrlDivId = "additionalMatchesUrl";

export class LoyaltyOfferOption {
	constructor(key, text) {
		this.label = text;
		this.value = key;
	}
}



export class OneOffScanCode extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loyaltyOfferId: '',
			numberOfMatches: null,
			dateCreated: null,
			dateUsed: null,
			loyaltyOffers : [],
			loading: true,
			errorSelectLoyaltyOffer: '',
			errorNumberOfMatches: '',
			helpVisible: false,
			submitButtonDisabled: false
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	async componentDidMount() {
		try {
			//TODO: Need to use context but am getting invalid hook calls and have spent far too much time on this already!
			await isLoggedIn();
			await this.getLoyaltyOfferData();
			if (!(await merchantHasLoyaltyOffers())) {
				window.location = '/loyaltyoffer';
			}
		}
		catch (error) {
			console.log(error);
			const networkErrorEmail = "mailto:tech@receiptsandrewards.com?subject=Login%20-%20Network%20Error&body=A%20User%20is%20unable%20to%20logon%20to%20" + window.location.host;
			var messageLabel = document.getElementById("response");
			ReactDOM.render(<label className={cssFormInputLabel} >There appears to be a network error. Please <a href={networkErrorEmail}>contact Receipts and Rewards</a> and we will fix it.</label>, messageLabel);
			this.setState({ loading: false, buttonDisabled: false });
		}
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleDropDownChange = (e, data) => {
		const name = data.name;
		this.setState({
			[name]: e.value
		})
	}

	handleSubmit = (event) => {
		const isValid = this.handleValidation();

		event.preventDefault();

		if (isValid) {
			this.setState({ loading: true, submitButtonDisabled: true })
			var loyaltyOfferAdditionalMatches = {
				loyaltyOfferId: this.state.loyaltyOfferId,
				numberOfMatches: this.state.numberOfMatches
			}

			var response = fetchSignedMessage(JSON.stringify(loyaltyOfferAdditionalMatches), 'oneoffscancode', postMethod);

			response.then(response => response.json()).then(data => { this.successfulResponse(data) }).catch(error => {this.failedResponse() });
		}
	}

	successfulResponse(response) {
		var qrAdditionalMatchesNode = document.getElementById("qrAdditionalMatchesCode")
		var additionalMatchesUrlNode = document.getElementById(additionalMatchesUrlDivId);

		var additionalMatchesQrCodeValue = process.env.REACT_APP_API_ENDPOINT + "oneoffscancode/" + response;

		ReactDOM.render(<div><QRCode value={additionalMatchesQrCodeValue} /></div>, qrAdditionalMatchesNode);
		ReactDOM.render(<label className={cssFormInputLabel}>{additionalMatchesQrCodeValue}</label>, additionalMatchesUrlNode);

		this.setState({ loading: false, submitButtonDisabled: false });
	}

	failedResponse() {
		var responseLabel = document.getElementById("response");
		ReactDOM.render(<label className={cssFormInputLabel}>Loyalty offer code generation failed. Please try again later.</label>, responseLabel);
		this.setState({ loading: false, submitButtonDisabled: false })
    }

	async getLoyaltyOfferData() {
		const response = await fetchSignedMessage(null, 'business/' + getSessionValue(businessIdHeader) + '/loyaltyoffers', getMethod);
		const data = await response.json();
		this.setState({ loyaltyOffers: data.map((loyaltyOffer) => new LoyaltyOfferOption(loyaltyOffer.loyaltyOfferId,  loyaltyOffer.description != null ? loyaltyOffer.description : loyaltyOffer.signUpAlertText)), loading: false });
	}

	DisplayLoyaltyOfferDropdown() {
		const { value } = this.state;
		if (this.state.loyaltyOffers.length == 1) {
			this.state.loyaltyOfferId = this.state.loyaltyOffers[0].value;
			return <div>{this.state.loyaltyOffers[0].label }</div>
		}
		else {
			return (<Select
				className={cssFormInputDropdown}
				placeholder='Select Loyalty Offer'
				fluid
				selection
				search
				options={this.state.loyaltyOffers}
				name="loyaltyOfferId"
				onChange={this.handleDropDownChange}
				value={value}
			/>
			);
		}
	}

	handleValidation() {
		let validated = true;
		if (this.state.numberOfMatches == null) {
			this.setState({ errorNumberOfMatches: "You must select a number of matches" });
			validated = false;
		} else {
			this.setState({ errorNumberOfMatches: "" });
		}

		if (this.state.loyaltyOfferId == '') {
			this.setState({ errorSelectLoyaltyOffer: "You must select a loyalty offer" });
			validated = false;
		} else {
			this.setState({ errorSelectLoyaltyOffer: "" });
		}

		return validated;
	}

	toggleHelp = (event) => {
		this.setState({
			helpVisible: !this.state.helpVisible
		});
	}

	render() {
		let loyaltyOfferDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayLoyaltyOfferDropdown();

		return (<LoyaltyOffersProvider>
				<NavMenu />
				<div>
					<form className={cssFormInput} onSubmit={this.handleSubmit}>
					<label className={cssFormInputLabel}>
						Transfer Points from Existing Cards
						{loyaltyOfferDropdown}
					</label>{this.state.errorSelectLoyaltyOffer && <p className={cssError}>{this.state.errorSelectLoyaltyOffer}</p>}

					<label className={cssFormInputLabel}>
						Number of Points to Add:
						<input className={cssFormInputNumber} type={numberField} name="numberOfMatches" value={this.state.numberOfMatches} onChange={this.handleInputChange} />
					</label>{this.state.errorNumberOfMatches && <p className={cssError}>{this.state.errorNumberOfMatches}</p>}


					<input type="submit" className={cssFormInputButton} value="Save" disabled={this.state.submitButtonDisabled} />
					<img src={this.state.helpVisible ? HideHelp : ShowHelp} className="btn-help" onClick={this.toggleHelp} />
					<div id="qrAdditionalMatchesCode" />
					<div id={ additionalMatchesUrlDivId }/>
					</form>
					<p id="help" className={"collapse" + (this.state.helpVisible ? ".in" : "")}>
					Use this form to transfer points collected on a card to the Recipts and Rewards App. Simply select the Loyalty Offer that your customer has collected points for, and then type in the number of points to add in the text box before pressing "Save". A QR code will be displayed which your customer can scan adding the points to their app.</p>
				{this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : ''}
				<div id="response" />

				</div></LoyaltyOffersProvider>
			);
	}
}
