import { useState, useEffect } from "react";
import Select from 'react-select';
import { isLoggedIn, businessIdHeader, businessesHeader, cssFormInputDropdown } from "./Library";
export class BusinessOption {
	constructor(key, text) {
		this.label = text;
		this.value = key;
	}
}

export function BusinessSelector() {

	const [businesses, setBusinesses] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedBusiness, setSelectedBusiness] = useState();

	useEffect(() => {
		const getBusinessData = async () => {
			setLoading(true);

			await isLoggedIn()
				.then((loggedIn) => {
					if (loggedIn) {
						setBusinesses((JSON.parse(sessionStorage.getItem(businessesHeader))).map((business) => new BusinessOption(business.businessId, business.businessName)));

						const businessId = JSON.parse(sessionStorage.getItem(businessIdHeader));
						const businesses = JSON.parse(sessionStorage.getItem(businessesHeader));
						if (!businessId.includes(",")) {
							const selectedBusiness = businesses.find((business) => business.businessId == businessId);
							setSelectedBusiness(new BusinessOption(selectedBusiness.businessId, selectedBusiness.businessName));
						}
					}
				}
				);

			setLoading(false);
		}
		getBusinessData();
	}, []);

	const SaveBusinessAndReload = (event) => {
		sessionStorage.setItem(businessIdHeader, JSON.stringify(event.value));
		setSelectedBusiness(event);
		window.location.reload();
	}

	const DisplayBusinessDropdown = () => {return businesses.length > 1 ?
		 (<label>
			Business<Select
			className={cssFormInputDropdown}
			placeholder='Select Business'
			fluid
			selection
			search
			options={businesses}
			name="businessId"
			onChange={(e) => SaveBusinessAndReload(e)}
			value={selectedBusiness}
			/>
		</label>

		) : "";
	};
	
	let businessDropdown = loading ? <p><em>Loading</em></p> : DisplayBusinessDropdown();

	return (
		<>
			{businessDropdown}
		</>
	)
}