import React, { Component } from 'react'
import { fetchSignedMessage, getSessionValue, businessIdHeader, cssFormInputLabel, isLoggedIn, getMethod } from './Library'
import QRCode from 'react-qr-code'
import Logo from '../img/logo.png'
import { LoyaltyOffersProvider } from '../LoyaltyOffersContext';
import { NavMenu } from '../components/NavMenu';
import DualBallSpinner from '../img/dual-ball-1s-100px.gif';
import ReactDOM from 'react-dom';

export class Tills extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tillsData: [],
            loading: true
        }
    }

    async componentDidMount() {
        await isLoggedIn();
        this.getTillsData();
    }

    async getTillsData() {
        try {
            const response = await fetchSignedMessage(null, 'business/' + getSessionValue(businessIdHeader) + '/tills', getMethod);
            const data = await response.json();
            
            this.setState({ tillsData: data, loading: false });
        }
        catch (error) {
            console.log(error);
            const networkErrorEmail = "mailto:tech@receiptsandrewards.com?subject=Login%20-%20Network%20Error&body=A%20User%20is%20unable%20to%20logon%20to%20" + window.location.host;
            var messageLabel = document.getElementById("qrCodes");
            ReactDOM.render(<label className={cssFormInputLabel} >There appears to be a network error. Please <a href={networkErrorEmail}>contact Receipts and Rewards</a> and we will fix it.</label>, messageLabel);
            this.setState({ loading: false, buttonDisabled: false });
        }
    }

    render() {
        return (<LoyaltyOffersProvider><NavMenu/>
            <div id="qrCodes" ><button className='form-input-button' onClick={() => { window.print(); return false; } }>Print QR Codes</button>
{
                    this.state.tillsData.map(till =>
                        <div className="qrCodePage" id={till.tillId}>
                        <div className="print-logo"><img src={Logo} className="print-logo"></img></div>
                        <div className="qrCode"><QRCode className="qr-image" value={process.env.REACT_APP_API_ENDPOINT + "receipt/till/" + till.tillId} /></div>
                        <div className="qr-scan-instructions"><label>Please scan QR Code to Update your Loyalty card and Obtain Receipt</label></div>
                        <div className="till-identifier"><label>{till.model + " - " + till.serial}</label></div>
                        </div>
                )
            }
            </div>
            {this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : ''}</LoyaltyOffersProvider>  
        );
    }
        
}